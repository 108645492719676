import config from "../../../config";
import * as constants from "./constants";
import {
  dataPostFetch,
  dataFetch,
  cartDataFetch,
  cartDataPost,
} from "../Fetch";
/**
 *  add product to cart
 */
export const addProductToCart = (product_id, quantity) => async (dispatch) => {
  try {
    // lets get category if we need
    const addedToCart = await cartDataPost(`add-item`, {
      product_id,
      quantity,
    });
    // const response = await dataFetch('shop', `cart`);
    dispatch({
      type: constants.SET_PRODUCT_CART,
      cart: addedToCart &&
        addedToCart.data && { [addedToCart.data.key]: addedToCart.data },
    });
  } catch (error) {
    console.log(error);
    // dispatch({
    //   type: constants.GET_CATEGORIES,
    // });
  }
};

/**
 *  add product to cart
 */
export const getCartItems = () => async (dispatch) => {
  try {
    dispatch({
      type: constants.GET_PRODUCT_CART,
    });
    const response = await cartDataFetch(`get-cart`);

    dispatch({
      type: constants.UPDATE_PRODUCT_CART,
      cart: response.data,
    });
  } catch (error) {
    console.log(error);
    // dispatch({
    //   type: constants.GET_CATEGORIES,
    // });
  }
};

/**
 *  add product to cart
 */
export const getShopStatus = () => async (dispatch) => {
  try {
    dispatch({
      type: constants.GET_SHOP_STATUS,
    });
    const response = await dataFetch("products", `system_status`);

    dispatch({
      type: constants.UPDATE_SHOP_STATUS,
      status: response.data,
    });
  } catch (error) {
    console.log(error);
    // dispatch({
    //   type: constants.GET_CATEGORIES,
    // });
  }
};
