import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../../utils/scroll';
import './styles.css';
import config from '../../../../config';

export default class Menu extends Component {
  constructor(props) {
    super(props);
  }

  scrollToId(link) {
    if (typeof window !== 'undefined') {
      const item = document.getElementById(link);
      scrollToItem(item, 150);
    }
  }

  renderLink(col) {
    if (col.link.match(/shopping-cart/)) {
      return (
        <a
          href={`#`}
          className={`${
            this.props.cartItems && this.props.cartItems > 0
              ? 'active'
              : 'notactive'
          } cart`}
          onClick={() =>
            this.props.toggleModal(
              `${config.apiURL}${col.link}`,
              'shopping-cart'
            )
          }
        >
          <i
            className={`icon-${
              this.props.cartItems > 0 && this.props.cartItems
                ? 'basket-loaded'
                : 'basket'
            } icons`}
          />
          <span className={'cartnumber'}>
            {this.props.cartItems > 0 && this.props.cartItems}
          </span>
          {/* {col.title} */}
        </a>
      );
    } else if (col.link[0] === '/') {
      return (
        <Link
          to={col.link}
          className={this.props.location === col.link ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    } else if (!this.props.isHome) {
      return (
        <Link
          to={`/#${col.link}`}
          className={this.props.location === col.link ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    } else {
      return (
        <a
          href={`#`}
          className={this.props.location === col.link ? 'active' : 'notactive'}
          onClick={() => this.scrollToId(col.link)}
        >
          {col.title}
        </a>
      );
    }
  }

  render() {
    return (
      <ul className={'wrap-menu clearfix'}>
        <li className={'logo'}>
          <Link to={`/${this.props.lang || ''}`} />
        </li>
        {this.props.content &&
          this.props.content.map((col, index) => (
            <li
              key={index}
              className={'fadeInRight animated'}
              style={{
                animationDelay: `${(index-.8) + 0.01}s`,
                animationDuration: `1s`,
              }}
            >
              {this.renderLink(col)}
            </li>
          ))}
        <li className={'right-menu'}>
          <a
            href={`#`}
            className={`search`}
            onClick={() => this.props.toggleSearch()}
          >
            <i className={`icon-magnifier icons`} />
          </a>
        </li>

        {this.props.rightmenu &&
          this.props.rightmenu.map((col, index) => (
            <li
              key={index}
              className={'right-menu fadeInRight animated'}
              style={{
                animationDelay: `${(index-.8) + 0.01}s`,
                animationDuration: `1s`,
              }}
            >
              {this.renderLink(col)}
            </li>
          ))}
      </ul>
    );
  }
}
