export function scrollToItem(item, buffer = 0) {
  if (typeof window === 'undefined') return null;
  const diff = (item.offsetTop - buffer - window.scrollY) / 8;
  if (Math.abs(diff) > 1) {
    window.scrollTo(0, window.scrollY + diff);
    clearTimeout(window._TO);
    window._TO = setTimeout(scrollToItem, 20, item, buffer);
  } else {
    window.scrollTo(0, item.offsetTop - buffer);
  }
}
