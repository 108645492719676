import React from 'react';
import { stack as Menu } from 'react-burger-menu';
import config from '../../../../config';

import Language from '../../../containers/Language';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../../utils/scroll';
import './styles.css';

function MobileMenu(props) {
  function scrollToReservation(link) {
    if (typeof window !== 'undefined') {
      const item = document.getElementById(link);
      scrollToItem(item, 150);
    }
  }

  function renderLink(col, index) {
    if (col.link.match(/shopping-cart/)) {
      return (
        <a
          key={index}
          href={`#`}
          className={`${
            props.cartItems && props.cartItems > 0 ? 'active' : 'notactive'
          } cart`}
          onClick={() =>
            props.toggleModal(`${config.apiURL}${col.link}`, 'shopping-cart')
          }
        >
          <i
            className={`icon-${
              props.cartItems > 0 && props.cartItems
                ? 'basket-loaded'
                : 'basket'
            } icons`}
          />
          <span className={'cartnumber'}>
            {props.cartItems > 0 && props.cartItems}
          </span>
          {/* {col.title} */}
        </a>
      );
    } else if (col.link[0] === '/') {
      return (
        <Link
          key={index}
          to={col.link}
          className={props.location === col.link ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    } else if (!props.isHome) {
      return (
        <Link
          key={index}
          to={`/#${col.link}`}
          className={props.location === col.link ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    } else {
      return (
        <a
          href={`#`}
          key={index}
          className={props.location === col.link ? 'active' : 'notactive'}
          onClick={() => scrollToReservation(col.link)}
        >
          {col.title}
        </a>
      );
    }
  }

  return (
    <div className={'wrap-mobile'}>
      <div className={'logo'}>
        <Link to={`/${props.lang || ''}`} />
      </div>
      <Menu right width={240}>
        <Language location={props.location} language={props.lang} />
        {props.submenu &&
          props.submenu.map((col, index) => renderLink(col, index))}
        {props.menu && props.menu.map((col, index) => renderLink(col, index))}
      </Menu>
      <div className={'rightmenu'}>
        {props.rightmenu &&
          props.rightmenu.map((col, index) => {
            if (!col.link.match(/shopping-cart/)) return null;
            return (
              <li
                key={index}
                className={'right-menu fadeInRight animated'}
                style={{
                  animationDelay: `${index + 0.1}s`,
                  animationDuration: `1s`,
                }}
              >
                {renderLink(col, index)}
              </li>
            );
          })}
      </div>
    </div>
  );
}

export default MobileMenu;
