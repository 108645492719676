import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import config from "../../config";

import Loadable from "react-loadable";
import AuthenticatedRoute from "../components/authenticated-route";
import UnauthenticatedRoute from "../components/unauthenticated-route";
import NotFound from "./not-found";

const Content = Loadable({
  loader: () => import(/* webpackChunkName: "content" */ "./content"),
  loading: () => null,
  modules: ["content"],
});

const Product = Loadable({
  loader: () => import(/* webpackChunkName: "product" */ "./product"),
  loading: () => null,
  modules: ["product"],
});

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ "./dashboard"),
  loading: () => null,
  modules: ["dashboard"],
});

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ "./login"),
  loading: () => null,
  modules: ["login"],
});

const Logout = Loadable({
  loader: () => import(/* webpackChunkName: "logout" */ "./logout"),
  loading: () => null,
  modules: ["logout"],
});

const Profile = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ "./profile"),
  loading: () => null,
  modules: ["profile"],
});

const languages = config.languages.join("|");

export default () => (
  <Switch>
    <Route exact path={`/:lang(${languages})?/NotFound`} component={NotFound} />
    <Route exact path="/:lang?/profile/:id" component={Profile} />
    <AuthenticatedRoute exact path="/:lang?/dashboard" component={Dashboard} />
    <UnauthenticatedRoute exact path="/:lang?/login" component={Login} />
    <AuthenticatedRoute exact path="/:lang?/logout" component={Logout} />

    <Route
      path={`/:lang(${languages})?/tipsandtricks/types/:category`}
      render={(props) => <Content type={"tipsandtricks"} {...props} />}
    />
    <Route
      path={`/:lang(${languages})?/tipsandtricks/:content`}
      render={(props) => <Content type={"tipsandtricks"} {...props} />}
    />

    <Route
      path={`/:lang(${languages})?/news/types/:category`}
      render={(props) => <Content type={"news"} {...props} />}
    />

    <Route
      path={`/:lang(${languages})?/news/:content`}
      render={(props) => <Content type={"news"} {...props} />}
    />

    {/* <Redirect from={`/:lang(${languages})?/products`} to="/" />
    <Redirect from={`/:lang(${languages})?/types/products`} to="/" />
    <Redirect from={`/:lang(${languages})?/product/*`} to="/" />
    <Redirect from={`/:lang(${languages})?/catalogue`} to="/" /> */}

    <Route
      path={`/:lang(${languages})?/products/types/:category`}
      render={(props) => <Content type={"products"} {...props} />}
    />

    <Route
      path={`/:lang(${languages})?/product/:content`}
      render={(props) => <Product type={"products"} {...props} />}
    />

    <Route
      path={`/:lang(${languages})?/inspirations/types/:category`}
      render={(props) => <Content type={"inspirations"} {...props} />}
    />
    <Route
      path={`/:lang(${languages})?/inspirations/:content`}
      render={(props) => <Content type={"inspirations"} {...props} />}
    />

    <Route exact path={`/:lang(${languages})?/:content?`} component={Content} />
    <Route component={NotFound} />
  </Switch>
);
