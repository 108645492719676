import WooCommerceAPI from "woocommerce-api";
import config from "../../../config";
import { http } from "../../utils/OfflineAxios";

export const WooCommerce = new WooCommerceAPI({
  url: "https://backtonature.cors.digital",
  consumerKey: "ck_fc6a965fe73cc3d36248efd9fdca344c5473d1cb",
  consumerSecret: "cs_a0d85f619451df5b18f308157265d7f71168e3ea",
  wpAPI: true,
  queryStringAuth: true,
  version: "wc/v2",
});

export const cartDataFetch = async (getUrl) => {
  return http.get(`${config.apiURL}/wp-json/cocart/v1/${getUrl}`, {
    withCredentials: true,
  });
};

export const cartDataPost = async (getUrl, data) => {
  return http.post(`${config.apiURL}/wp-json/cocart/v1/${getUrl}`, data, {
    withCredentials: true,
  });
};

export const dataFetch = async (type, getUrl) => {
  if (type === "products") {
    const response = await WooCommerce.getAsync(getUrl);
    //lets normalize response
    return { data: response && JSON.parse(response.body) };
  } else {
    return http.get(
      `${config.apiURL}/wp-json/${type === "shop" ? "wc" : "wp"}/v2/${getUrl}`,
      { withCredentials: true }
    );
  }
};

export const dataPostFetch = async (type, getUrl, data) => {
  if (type === "products") {
    const response = await WooCommerce.post(getUrl, data);
    //lets normalize response
    return { data: response && JSON.parse(response.body) };
  } else {
    return http.post(
      `${config.apiURL}/wp-json/${type === "shop" ? "wc" : "wp"}/v2/${getUrl}`,
      data,
      { withCredentials: true }
    );
  }
};
