import * as constants from './constants';

const initialState = {
  status: 'set',
  cart: {},
  totalItems: null,
  categories: {},
  currency: 'EUR',
  currency_symbol: '&euro;',
};

export default (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case constants.GET_PRODUCT_CART:
      return { ...state, status: 'getting' };
    case constants.SET_PRODUCT_CART:
      newState = {
        ...state,
        status: 'set',
        cart:
          action.cart && action.cart !== 'Cart is empty!'
            ? { ...state.cart, ...action.cart }
            : {},
      };

      newState.totalItems = Object.keys(newState.cart).reduce((prev, curr) => {
        if (newState.cart[curr] && newState.cart[curr].quantity) {
          return (prev || 0) + newState.cart[curr].quantity;
        }
        return prev;
      }, 0);

      return newState;

    case constants.UPDATE_PRODUCT_CART:
      newState = {
        ...state,
        status: 'set',
        cart:
          action.cart && action.cart !== 'Cart is empty!'
            ? { ...action.cart }
            : {},
      };

      newState.totalItems = Object.keys(newState.cart).reduce((prev, curr) => {
        if (newState.cart[curr] && newState.cart[curr].quantity) {
          return (prev || 0) + newState.cart[curr].quantity;
        }
        return prev;
      }, 0);

      return newState;
    case constants.GET_SHOP_STATUS:
      return { ...state, status: 'getting' };
    case constants.UPDATE_SHOP_STATUS:
      return {
        ...state,
        currency: action.status.settings.currency,
        currency_symbol: action.status.settings.currency_symbol,
      };

    default:
      return state;
  }
};
