import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { loadingBarMiddleware } from "react-redux-loading-bar";

import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import rootReducer from "./modules";

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export default (url = "/") => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory();

  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === "development" && !isServer) {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [
    thunk,
    routerMiddleware(history),
    loadingBarMiddleware({
      promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"],
    }),
  ];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Grab the state from a global variable injected into the server-generated HTML
  const preloadedState = window.__PRELOADED_STATE__;

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__;

  // Create Redux store with initial state
  const store = createStore(
    connectRouter(history)(rootReducer),
    preloadedState || {},
    composedEnhancers
  );

  // Tell react-snap how to save Redux state
  window.snapSaveState = () => ({
    __PRELOADED_STATE__: store.getState(),
  });

  return {
    store,
    history,
  };
};
