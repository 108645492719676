import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SVG from 'react-inlinesvg';
import Modal from 'react-responsive-modal';
import Iframe from 'react-iframe';

import Slider from 'react-slick';
import { Container } from 'reactstrap';

import Menu from '../Menus/Menu';
import MobileMenu from '../Menus/MobileMenu';
import SubMenu from '../Menus/SubMenu';
import Search from '../Search';
import animated from '../../assets/logo_anim_animated.svg';
import { toggleModal } from '../../containers/Menus/actions';
import { getCartItems } from '../../containers/Shop/actions';
import { getSearch, toggleSearch } from '../../containers/Search/actions';
import './styles.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stikyClass: false,
      searchOpen: false,
      headerClass: props.isHome ? 'header' : 'header inner',
    };
    this.props.getCartItems();
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === 'set' && this.props.status !== prevProps.status) {
      this.setState({
        headerClass: prevProps.isHome ? 'header' : 'header inner',
      });
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    if (window) window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    if (window) window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    const currentHeight =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (currentHeight > 50 && !this.state.handleScroll) {
      this.setState({ handleScroll: true });
    } else if (currentHeight < 50 && this.state.handleScroll) {
      this.setState({ handleScroll: false });
    }
  }

  toggleModal(url, id) {
    this.props.toggleModal(url, id);
    if (
      this.props.cartItems &&
      this.props.cartItems > 0 &&
      this.props.modalURL &&
      this.props.cartStatus === 'set'
    ) {
      this.props.getCartItems();
    }
  }

  render() {
    const { head } = this.props.content;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: head && head.length > 1,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className={this.state.headerClass}>
        <div className={'madeIn'}>
          <span>
            made in <strong>Sweden</strong>
          </span>
        </div>

        {head && (
          <Slider {...settings}>
            {head.map((slide, index) => (
              <div key={index} className={'header-wrapper'}>
                <div
                  className={'header-slide'}
                  style={{
                    backgroundImage: `url('${slide.slide}')`,
                  }}
                >
                  <div className={'read-safe'} />
                  <div className={'abolute-elemtns'}>
                    <Container>
                      {slide.title && (
                        <h2 dangerouslySetInnerHTML={{ __html: slide.title }} />
                      )}
                      {slide.sub_title && (
                        <h3
                          dangerouslySetInnerHTML={{ __html: slide.sub_title }}
                        />
                      )}
                      {slide.text && (
                        <p dangerouslySetInnerHTML={{ __html: slide.text }} />
                      )}
                      {slide.button && (
                        <a className={'action'} href={slide.link}>
                          {slide.button}
                        </a>
                      )}
                    </Container>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}

        <div
          className={`menus clearfix ${this.state.handleScroll ? 'fixed' : ''}`}
        >
          <MobileMenu
            isHome={this.props.isHome}
            menu={[...this.props.topmenu, ...this.props.shopmenu]}
            rightmenu={this.props.shopmenu}
            cartItems={this.props.cartItems}
            location={this.props.current}
            submenu={this.props.submenu}
            lang={this.props.lang}
            toggleModal={this.toggleModal}
          />
          <SubMenu
            content={this.props.submenu}
            location={this.props.current}
            lang={this.props.lang}
            toggleModal={this.toggleModal}
          />
          <Menu
            isHome={this.props.isHome}
            content={this.props.topmenu}
            rightmenu={this.props.shopmenu}
            location={this.props.current}
            lang={this.props.lang}
            cartItems={this.props.cartItems}
            toggleModal={this.toggleModal}
            toggleSearch={this.props.toggleSearch}
          />
        </div>
        <div>
          <Modal
            open={this.props.modalOpen}
            onClose={this.toggleModal}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            classNames={{ modal: 'cart-modal' }}
            center
          >
            {typeof this.props.modalURL == 'string' && (
              <Iframe
                url={this.props.modalURL}
                width={'100%'}
                height={'100%'}
                className="iframe-content"
                display="initial"
                position="relative"
                allowFullScreen
              />
            )}
          </Modal>
        </div>
        <Search />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  topmenu: state.menus.topmenu,
  submenu: state.menus.submenu,
  shopmenu: state.menus.shopmenu,
  status: state.content.status,
  modalOpen: state.menus.modal.open,
  modalURL: state.menus.modal.source,
  cartItems: state.shop.totalItems,
  cartStatus: state.shop.status,
  content: state.content.currentContent && state.content.currentContent.acf,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleModal, getCartItems, toggleSearch }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

Header.propTypes = {
  content: PropTypes.object,
  topmenu: PropTypes.array,
  submenu: PropTypes.array,
};

Header.defaultProps = {
  content: {
    slides: [],
  },
  topmenu: [],
  submenu: [],
};
