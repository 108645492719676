import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import config from '../../../config';
function ReadMore(props) {
  const thisLink = props.link.replace(config.websiteURL, '');
  if (thisLink.match(/http/)) {
    return (
      <a className={'readmore'} onClick={props.hook} href={thisLink}>
        {props.children} <i className={'icons icon-arrow-right'} />
      </a>
    );
  } else {
    return (
      <Link className={'readmore'} onClick={props.hook} to={thisLink}>
        {props.children} <i className={'icons icon-arrow-right'} />
      </Link>
    );
  }
}

export default ReadMore;
