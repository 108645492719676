import * as constants from './constants';
import config from '../../../config';

const initialState = {
  modal: {
    id: '',
    open: false,
    source: '',
  },
  topmenu: [],
  submenu: [],
  shopmenu: [],
};
const regexWebsite = new RegExp(config.websiteURL);

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.TOGGLE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          open: !state.modal.open,
          id:
            action.payload.id && action.payload.id !== ''
              ? action.payload.id
              : state.modal.id,
          source:
            action.payload.url &&
            typeof action.payload.url == 'string' &&
            action.payload.url !== ''
              ? action.payload.url
              : state.modal.source,
        },
      };
    case constants.SET_CURRENT_MENUS:
      return {
        ...state,
        topmenu:
          action.menus.topmenu.items &&
          action.menus.topmenu.items.map(menu => {
            return {
              link: menu.url.replace(regexWebsite, ''),
              title: menu.title,
            };
          }),
        submenu:
          action.menus.submenu.items &&
          action.menus.submenu.items.map(menu => {
            return {
              link: menu.url.replace(regexWebsite, ''),
              title: menu.title,
            };
          }),
        shopmenu:
          action.menus.shopmenu.items &&
          action.menus.shopmenu.items.map(menu => {
            return {
              link: menu.url.replace(regexWebsite, ''),
              title: menu.title,
            };
          }),
      };

    default:
      return state;
  }
};
