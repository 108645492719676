import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { SocialIcon } from "react-social-icons";

import "./styles.css";
import logo from "../../assets/logo.svg";

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.props.content) return null;
    const {
      text,
      email,
      phone,
      address1,
      address2,
      follow,
      face,
      tube,
    } = this.props.content;

    return (
      <div className={"footer"}>
        <div className={"followME animated fadeInLeft"}>
          <div className={"ico"}>
            <SocialIcon
              network="instagram"
              fgColor={"#fff"}
              style={{ height: 40, width: 40 }}
              url="https://www.instagram.com/backtonature_aquarium/"
            />
          </div>{" "}
          <div className={"ico"}>
            <SocialIcon
              network="facebook"
              fgColor={"#fff"}
              style={{ height: 40, width: 40 }}
              url="https://www.facebook.com/backtonatureaquarium"
            />
          </div>{" "}
          <div className={"ico"}>
            <SocialIcon
              network="facebook"
              bgColor={"#ff5a01"}
              fgColor={"#fff"}
              style={{ height: 40, width: 40 }}
              url="https://www.facebook.com/groups/240792200787150"
            />
          </div>
        </div>
        <Container>
          <Row>
            <Col xs="12" md="6">
              <div className={"brown-wrapper"}>
                <img src={logo} alt={"back to nature"} />
                <p dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </Col>
            <Col xs="12" md="3">
              <p>
                <span>{email}</span>
                <br />
                <span>{phone}</span>
              </p>
              <p>
                <span>{address1}</span>
                <br />
                <span>{address2}</span>
              </p>
            </Col>
            <Col xs="12" md="3">
              <p className={"follow"}>{follow}</p>
              <p>
                {face && (
                  <a className={"social"} href={face} target="_blank">
                    <i className={"icons icon-social-facebook"} />
                  </a>
                )}
                {tube && (
                  <a className={"social"} href={tube} target="_blank">
                    <i className={"icons icon-social-youtube"} />
                  </a>
                )}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
