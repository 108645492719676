export default {
  apiURL: 'https://backtonature.cors.digital',
  wcapiURL: 'https://backtonature.se/wcapi?requesturl=',
  websiteURL: 'https://backtonature.cors.digital',
  languages: ['de', 'en'],
  listSize: 9,
  defaultLang: 'de',
  axiosDevHeader: {
    consumerKey: 'ck_5548258219fa3ae1e3c198abf216477cf8769adf',
    consumerSecret: 'cs_ba4cb0292737badbf44d0a13499b559915814ae7',
  },
};
