import React from 'react';
import { Link } from 'react-router-dom';
import Language from '../../../containers/Language';
import './styles.css';
import config from '../../../../config';

function SubMenu(props) {
  function renderLink(col) {
    if (col.link.match(/my-account/)) {
      return (
        <a
          href={`#`}
          className={props.location === col.link ? 'active' : 'notactive'}
          onClick={() =>
            props.toggleModal(`${config.apiURL}${col.link}`, 'my-account')
          }
        >
          {col.title}
        </a>
      );
    } else if (col.link[0] === '/') {
      return (
        <Link
          className={props.location === col.link ? 'active' : 'notactive'}
          to={col.link}
        >
          {col.title}
        </Link>
      );
    } else if (!props.isHome) {
      return (
        <Link
          to={`/#${col.link}`}
          className={props.location === col.link ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    }
  }

  return (
    <div className={'submenu clearfix'}>
      <ul>
        <li>
          <Language location={props.location} language={props.lang} />
        </li>
        {props.content.map((col, index) => (
          <li
            key={index}
            // className={'fadeIn animated'}
            style={{
              animationDelay: `${index + 0.1}s`,
              animationDuration: `1s`,
            }}
          >
            {renderLink(col)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SubMenu;
